<template>
	<el-dialog title="总监设置" :close-on-click-modal="false" :visible.sync="visible" width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-form-item label="姓名" prop="nodeName">
				<el-input class="selItemInput" v-model="dataForm.personName" placeholder="请输入总监名称" style="width:300px">
				</el-input>
			</el-form-item>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick @click="visible = false" class="primaryPlainBtn" type="primary" plain>取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "setingPerson",
		data() {
			return {
				visible: false,
				inline: false,
				dataForm: {
					workNo: $common.getItem("workNo"),
					jobKind: 1,
					personName: "",
					recId:""
				},
				dataRule: {
					personName: [{
						required: true,
						message: "姓名不能为空",
						trigger: "blur"
					}]
				},
			}
		},
		methods: {
			init(id) {
				this.dataForm.recId = id || 0;
				this.visible = true;
			},
			// 表单提交
			dataFormSubmit() {
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						let _url = "/business/projectpersoninfo/save";
						if(this.dataForm.recId && this.dataForm.recId != 0){
							_url = "/business/projectpersoninfo/update";
						}
						this.$http({
						  url: this.$store.state.httpUrl + _url,
							method: "post",
							data: this.dataForm
						}).then(({
							data
						}) => {
							if (data && data.resultCode === 200) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										this.visible = false;
										this.$emit("refreshDataList");
									}
								});
							} else {
								this.$message.error(data.msg);
							}
						});
					}
				});
			},
		}
	}
</script>
<style scoped>

</style>
